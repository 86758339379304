import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL, uploadBytesResumable, deleteObject } from 'firebase/storage';
import {db, auth} from '../firebase';
import { collection, getDoc, doc, addDoc, updateDoc } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import '../Pages/EditItemPage.css';
import ArrowBackIcon from '../assets/arrow-left-solid.svg';
import { v4 as uuidv4 } from 'uuid';
import TrashSolid from '../assets/trash-solid.js';

const EditItemPage = () => {
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState({});
  const { username, id } = useParams();
  
  const [name, setItemName] = useState('');
  const [state, setItemState] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [itemPrice, setItemPrice] = useState('');
  const [imageFile, setImageFile] = useState(null); // State for the image file
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(null);  // State to hold preview image URL
  const [isSaving, setIsSaving] = useState(false);

  const [previewUrls, setPreviewUrls] = useState([]); // State to hold multiple preview image URLs
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [newImages, setNewImages] = useState([]); // State to hold new images to be uploaded
  const [isUploading, setIsUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const [initialUrls, setInitialUrls] = useState([]); // Define state variable for initialUrls


  useEffect(() => {

    const currentUser = auth.currentUser;
    
    if (!currentUser) {
      // If there is a current user and it's not the one in the URL, redirect
      navigate("/login");  // or some other route
    }

    const getItem = async () => {
      setLoading(true);
      const itemDoc = await getDoc(doc(db, 'stores',id));
      const currentUser = auth.currentUser;
      setUser(currentUser);
      if (itemDoc) {
        const item = {...itemDoc.data(), id: itemDoc.id}; // Add id here
        setItem(item);
        setItemName(item.item_name);
        setItemDescription(item.item_description);
        setItemPrice(item.item_price);
        setPreviewImage(item.item_image);  // Set initial preview image to current item image
        setPreviewUrls(item?.item_image || []);
        setInitialUrls(item?.item_image || []);
        setItemState(item.archived ? 'sold' : 'available');
      } else {
        console.log("No such document!");
      }
      setLoading(false);
    };
    getItem();
  }, [username, id]);
  

  const goBack = () => {
    navigate(-1);
  }

  const onImageChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);

    // Generate a URL for the selected file for previewing
    const previewUrl = URL.createObjectURL(file);
    setPreviewImage(previewUrl);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const newUrls = newFiles.map((file) => URL.createObjectURL(file));
    setFilesToUpload([...filesToUpload, ...newFiles]);
    setPreviewUrls([...previewUrls, ...newUrls]);
    console.log(filesToUpload);
    console.log(previewUrls);
  }
  
  const handleRemoveFile = (index) => {
    console.log("Removing file at index", index);
    console.log("Before Removal - filesToUpload", filesToUpload);
    console.log("Before Removal - previewUrls", previewUrls);
    
    const newFilesToUpload = index < initialUrls.length 
      ? filesToUpload 
      : filesToUpload.filter((_, idx) => idx !== index - initialUrls.length);
    
    const newPreviewUrls = previewUrls.filter((_, idx) => idx !== index);
    
    setFilesToUpload(newFilesToUpload);
    setPreviewUrls(newPreviewUrls);
    
    console.log("After Removal - filesToUpload", newFilesToUpload);
    console.log("After Removal - previewUrls", newPreviewUrls);
  }
  

  const uploadFiles = async (files, folderUUID, storage) => {
    return await Promise.all(files.map(async (file, index) => {
      const fileUUID = uuidv4();
      const storageRef = ref(storage, `${item.userID}/items/${folderUUID}/${fileUUID}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      return new Promise((resolve, reject) => {
        uploadTask.on('state_changed', 
          (snapshot) => {
            // Handle the progress
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
          },
          (error) => {
            console.error(error);
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          }
        );
      });
    }));
  }  

  const saveChanges = async (event) => {
    event.preventDefault();
    setIsSaving(true);
    setIsUploading(true);

    const storage = getStorage();
    const folderUUID = id;

    try {
      const newDownloadURLs = await uploadFiles(filesToUpload, folderUUID, storage);
      const imageInfo = newDownloadURLs.map(url => {
        return {url, path: `${item.userID}/items/${folderUUID}/${uuidv4()}`};
      });


      console.log("newDownloadURLs: ", newDownloadURLs); // Debugging log for newly obtained download URLs
      
      // Here the existingURLs should filter out the blob URLs and leave the existing URLs that are not associated with the current upload.
      const existingURLs = previewUrls.filter(url => 
        !url.startsWith('blob:') && 
        !filesToUpload.some(file => URL.createObjectURL(file) === url)
      );      
      console.log("existingURLs: ", existingURLs); // Debugging log for existing URLs after filtering out blob URLs
      
      // Here you merge the existingURLs with the newDownloadURLs to get the finalURLs.
      const finalURLs = [...existingURLs, ...newDownloadURLs];
      console.log("finalURLs: ", finalURLs); // Debugging log for final array of URLs to be uploaded to Firestore

      const removedUrls = initialUrls.filter(url => !finalURLs.includes(url));
      console.log("removedUrls: ", removedUrls); // Debugging log for removed URLs

      console.log("initialUrls: ", initialUrls);
      console.log("finalURLs: ", finalURLs);
      initialUrls.forEach(url => {
        console.log(`Is ${url} included in finalURLs?`, finalURLs.includes(url));
      });

      // Use state values directly
      const item_name = name;
      const item_price = Number(itemPrice);
      const item_description = itemDescription;
      console.log("item_state: ", state);
      const item_state = state === 'sold' ? true : false;

      await Promise.all(removedUrls.map(async url => {
        // Log the original URL
        console.log("to delete: ", url);
        const storageRef = ref(storage, url);
        try {
          await deleteObject(storageRef);
        } catch (error) {
          console.error("Error Deleting: ", url, error);
        }
        
      }));
      

      // Construct the newItem object
      const updatedItem = {
          item_image: finalURLs,
          item_name,
          item_price,
          item_description,
          archived: item_state
          // You can include other fields to update here, excluding the ones you don't want to modify.
      };

      // Update the existing doc
      const itemRef = doc(db, "stores", id);
      await updateDoc(itemRef, updatedItem);

      setShowModal(false);

      setIsSaving(false);
    } catch (error) {
        console.error("Error during the upload or Firestore save operation: ", error);
    } finally {
        setIsSaving(false);
        setIsUploading(false);
    }
}

  
  const numberFormat = (value) =>
  new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS'
  }).format(value);
  

  return (
    <div className="item-page">
      <button className="back-button" onClick={goBack}>
        <img src={ArrowBackIcon} alt="Back" />
      </button>
      
      <div style={{ 
        overflowX: 'auto', 
        whiteSpace: 'nowrap',
        scrollSnapType: 'x mandatory',
        marginBottom: '25px',
      }}>
        <style>
          {`
            div::-webkit-scrollbar {
              display: none;
            }
          `}
        </style>
        {previewUrls.map((url, index) => (
          <div style={{ 
            position: 'relative', 
            display: 'inline-block', 
            marginRight: '25px' 
          }} key={index}>
            <img src={url} 
              alt="Preview" 
              style={{ 
                width: '100px', 
                height: '100px', 
                objectFit: 'cover',
              }} 
            />
            <button style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: '#f3f3f3',
              border: 'none',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0',
            }} 
              onClick={(e) => { e.preventDefault(); handleRemoveFile(index); }}
            >
              <TrashSolid style={{ width: '15px', height: '15px', fill: '#000' }} />
            </button>
          </div>
        ))}
      </div>
      <label className="change-button">
        <span>Add Images</span>
        <input 
          type="file" 
          id="fileInput" 
          multiple
          name="item_image" 
          required 
          onChange={handleFileChange}
          style={{display: 'none'}}
        />
      </label>
      
      <div className="item-text-container">
        <label>
          Item Name:
          <input type="text" name="item_name" value={name} onChange={(e) => setItemName(e.target.value)} />
        </label>
        <label>
          Item Description:
          <textarea value={itemDescription} onChange={(e) => setItemDescription(e.target.value)}></textarea>
        </label>
        <label>
          Item Price:
          <input type="number" value={itemPrice} onChange={(e) => setItemPrice(e.target.value)} />
        </label>
        <label>
          Item State:
          <select name="item_state" value={state} onChange={(e) => setItemState(e.target.value)}>
            <option value="available">Available</option>
            <option value="sold">Sold</option>
          </select>
        </label>
      </div>
      <button className="save-button" onClick={saveChanges} disabled={isSaving}>{isSaving ? 'Saving...' : 'Save Changes'}</button>
    </div>
  );  
};

export default EditItemPage;
