import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {db} from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import '../Pages/Store.css';

const Store = ({username}) => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const q = query(collection(db, 'stores'), where("username", "==", username));
    getDocs(q)
      .then((snapshot) => {
        if(!snapshot.empty){
          setItems(snapshot.docs.map(doc => doc.data()));
        }
      });
  }, [username]);

  const numberFormat = (value) =>
  new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS'
  }).format(value);

  const handleItemClick = (item) => {
    navigate(`/${username}/${item.item_name}/${item.id}`);
  }

  return (
    <div className="store-container">
      {items.map((item, index) => (
        <div 
          key={index} 
          onClick={() => handleItemClick(item)} 
          style={{ cursor: 'pointer' }}
        >
          <div className="store-item">
            <img src={item.item_image[0]} alt={item.item_name} className="store-item-img" />
            <div className="condition-container-store">
              {item.archived && <div className="item-condition-status-store sold">SOLD</div>}
            </div>
          </div>
          <div className="item-text-container-store">
            <h2 style={{fontSize:'14px'}}>{item.item_name}</h2>
            <h3 style={{fontSize:'12px'}}>{numberFormat(item.item_price)}</h3>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Store;

