import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronRight } from '../assets/chevron-right.svg';
import { ReactComponent as ChevronUp } from '../assets/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../assets/chevron-down.svg';
import { ReactComponent as PasteIcon } from '../assets/paste-icon.svg';
import { ReactComponent as FloppyDisk } from '../assets/floppy-disk.svg';
import { ReactComponent as ChevronCheck } from '../assets/checkmark.svg'; // Import the checkmark icon
import '../spinner.css';
import '../Pages/SelectPayment.css';
import { db, auth } from '../firebase';
import { collection, getDocs, query, where, updateDoc, doc, arrayUnion } from 'firebase/firestore';

const PaymentOptions = () => {
  const [cbuAliasOpen, setCbuAliasOpen] = useState(false);
  const [accountNumOrAlias, setAccountNumOrAlias] = useState('');
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [uploading, setUploading] = useState(false); // New state for upload status
  const [isUploaded, setIsUploaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsInputEmpty(accountNumOrAlias === '');
  }, [accountNumOrAlias]);

  const handleInput = (e) => {
    setAccountNumOrAlias(e.target.value);
  };

  const handleMercadoPago = () => {
    const clientId = process.env.REACT_APP_MP_APP_ID;
    const redirectUri = process.env.REACT_APP_MP_REDIRECT_URI;
    const state = Math.random().toString(36).substring(7);
    const mpAuthUrl = `https://auth.mercadopago.com/authorization?client_id=${clientId}&response_type=code&platform_id=mp&state=${state}&redirect_uri=${redirectUri}`;
    window.location.href = mpAuthUrl;
  };

  const handleCBUAliasOpen = () => {
    setCbuAliasOpen(!cbuAliasOpen);
  };

  const handleContinue = () => {
    if (!isInputEmpty) {
      saveToFirebase();
    }
    navigate('/select-shipping');
  };

  const pasteFromClipboard = async () => {
    if (!navigator.clipboard) {
      return;
    }
    const text = await navigator.clipboard.readText();
    setAccountNumOrAlias(text);
  };

  const saveToFirebase = async () => {
    if (auth.currentUser.uid && !uploading) {
      setUploading(true);

      const q = query(collection(db, "users"), where("uid", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      let docId = null;
      querySnapshot.forEach((doc) => {
        docId = doc.id;
      });

      if (docId) {
        const userDoc = doc(db, 'users', docId);
        await updateDoc(userDoc, {
          transfer: accountNumOrAlias,
          payment_settings: arrayUnion("CBU")
        });
        setUploading(false);
        setIsUploaded(true);
      }
    }
  };

  return (
    <div className="dashboard-wrapper">
    <h1>Configurar Pagos</h1>
    <button onClick={handleMercadoPago} className="payment-button">
         Configurar Mercado Pago  *
        <div className="icon-button">
        <ChevronRight />
        </div>
    </button>
    <div className="payment-option">
        <div onClick={handleCBUAliasOpen} className="payment-option-title">
            Agregar CBU/Alias
            <div className="icon-button">
            {cbuAliasOpen ? <ChevronDown /> : <ChevronUp />}
            </div>
        </div>
        {cbuAliasOpen && 
            <div style={{ display: 'flex', alignItems: 'center', padding: '1em' }}>
            {uploading || isUploaded
            ? <span>{accountNumOrAlias}</span>
            : <input 
                type="text" 
                value={accountNumOrAlias} 
                onChange={handleInput} 
                className="payment-input"
                placeholder="CBU / Alias"
                />}
            <div onClick={isInputEmpty ? pasteFromClipboard : saveToFirebase}>
                {uploading 
                    ? <div className="spinner"></div> 
                    : isUploaded 
                    ? <ChevronCheck /> 
                    : isInputEmpty 
                        ? <PasteIcon />
                        : <FloppyDisk />}
            </div>
            </div>
        }
        </div>
    <button onClick={handleContinue} className="payment-button black">
        Continuar
        <div className="icon-button-contiune">
        <ChevronRight />
        </div>
    </button>
    <p style={{textAlign: "left !important", alignItems: "flex-start !important", fontSize: "10px"}}>*  1% (fee Wearly) + hasta 6.39% + IVA (fee <a href='https://www.mercadopago.com.ar/ayuda/costo-recibir-pagos_220'>MercadoPago</a>)</p>
    </div>

  );
};

export default PaymentOptions;
