import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable } from "@firebase/functions";
import { db, auth } from '../firebase';
import '../spinner.css';
import { collection, getDocs, query, where, updateDoc, doc, getDoc, addDoc, deleteDoc } from 'firebase/firestore';


const MercadoPago = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // Parse the 'code' from URL
  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get('code');

  const functions = getFunctions();
  const mercadoPagoAuth = httpsCallable(functions, 'mercadoPagoAuth');

  useEffect(() => {
    const fetchData = async () => {
      const docSnapshot = await getDoc(doc(db, "users", auth.currentUser.uid, "private", "data"));
          mercadoPagoAuth({uid: auth.currentUser.uid, code: code})
            .then((result) => {
              // check the response
              if (result.data.state === true) {
                navigate('/dashboard');
              } else {
                navigate('/dashboard');
              }
            })
            .catch((error) => {
              navigate('/dashboard');
            });
    
    }
  
    fetchData();
  }, [code, mercadoPagoAuth, navigate]);
  

  const retryMP = () => {
    const clientId = process.env.REACT_APP_MP_APP_ID;
    const redirectUri = process.env.REACT_APP_MP_REDIRECT_URI;
    const state = Math.random().toString(36).substring(7); // create a random string for state parameter
    const mpAuthUrl = `https://auth.mercadopago.com/authorization?client_id=${clientId}&response_type=code&platform_id=mp&state=${state}&redirect_uri=${redirectUri}`;

    // Redirect the user to MercadoPago authorization URL
    window.location.href = mpAuthUrl;
  }

  return (
    <div>
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <>
          <p>Error occurred while trying to authenticate with Mercado Pago.</p>
          <button onClick={retryMP}>Retry Connection</button>
        </>
      )}
    </div>
  );
};

export default MercadoPago;

