import React, { useState, useEffect } from 'react';
import { collection, doc, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../Pages/Sales.css';
import ArrowBackIcon from '../assets/arrow-left-solid.svg';
import { ReactComponent as Shipping } from '../assets/truck-fast.svg';
import Select from 'react-select';

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [totalSales, setTotalSales] = useState(0); // Add this state

  const goBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    const getSalesData = async () => {
      setLoading(true);
      const userRef = query(collection(db, 'users'), where("uid", "==", auth.currentUser.uid));
      const userSnapshot = await getDocs(userRef);


      if (!userSnapshot.empty) {
        const userSales = query(collection(db, 'users', userSnapshot.docs[0].id, 'sales'));
        const salesSnapshot = await getDocs(userSales);

        if (!salesSnapshot.empty) {
          const salesData = salesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setSales(salesData);
          const salesTotal = salesSnapshot.docs.reduce((total, doc) => {
            const saleData = doc.data();
            return total + (saleData.item_price || 0);
          }, 0);
          setTotalSales(salesTotal);
        }
      }
      setLoading(false);
    }
    getSalesData();
  }, []);

  const optionsMP = [
    { value: 'Payed & Ready to Send', label: 'Payed & Ready to Send', color: '#99DE8E' },
    { value: 'Package Sent', label: 'Package Sent', color: '#81a7e3' }
  ];
  
  const optionsTransferencia = [
    { value: 'Awaiting Payment', label: 'Awaiting Payment', color: '#E3645B' },
    { value: 'Payed & Ready to Send', label: 'Payed & Ready to Send', color: '#99DE8E' },
    { value: 'Package Sent', label: 'Package Sent', color: '#81a7e3' }
  ];  

  const numberFormat = (value) =>
  new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS'
  }).format(value);

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = data.color;
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? color
          : isFocused
          ? null
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? 'black'
          : isFocused
          ? 'black'
          : data.color,
        menuPortal: (base) => ({ ...base, zIndex: 9999, width: 'auto !important', cursor: 'pointer' }),
        menu: (base) => ({ ...base, width: '100%', cursor: 'pointer' }),
      };
    },
  };
  
  const handleRowClick = (saleId) => {
    if (selectedRow === saleId) {
      setSelectedRow(null);
    } else {
      setSelectedRow(saleId);
    }
  }

  const handleStatusChange = async (saleId, newStatus) => {
    const userRef = query(collection(db, 'users'), where("uid", "==", auth.currentUser.uid));
    const userSnapshot = await getDocs(userRef);

    const salesDoc = doc(db, 'users', userSnapshot.docs[0].id, 'sales', saleId);
    await updateDoc(salesDoc, { status: newStatus });
    setSales(prevSales => 
      prevSales.map(sale => 
        sale.id === saleId ? { ...sale, status: newStatus } : sale
      )
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const awaitingPaymentSales = sales.filter(sale => sale.status === 'Awaiting Payment');
  const readyToSendSales = sales.filter(sale => sale.status === 'Payed & Ready to Send');
  const packageSentSales = sales.filter(sale => sale.status === 'Package Sent');

  awaitingPaymentSales.sort((a, b) => b.order_date.seconds - a.order_date.seconds);
  readyToSendSales.sort((a, b) => b.order_date.seconds - a.order_date.seconds);
  packageSentSales.sort((a, b) => b.order_date.seconds - a.order_date.seconds);

  return (
    <div className='sales-wrapper'>
        <div className='sales-container'>
        <div className="dashboard-header-sales">
            <button className="back-button-sales" onClick={goBack}>
                <img src={ArrowBackIcon} alt="Back" />
            </button>
            <h1>Ventas</h1>
        </div>

        <h2>Ventas Totales: {numberFormat(totalSales.toFixed(2))}</h2>

        {sales.length === 0 ?
         (<p>No Tienes Ventas Todavia! 😔</p>) :
         (
          <div>
            <a href='https://wearlyapp.com/how-to-send' className='how-to-send-link'>Guia para enviar tus paquetes</a>
            { awaitingPaymentSales.length != 0 && <div className="horizontal-scrolling"><SaleTable sales={awaitingPaymentSales} title="Awaiting Payment" handleRowClick={handleRowClick} handleStatusChange={handleStatusChange} optionsMP={optionsMP} optionsTransferencia={optionsTransferencia} colourStyles={colourStyles} selectedRow={selectedRow} /></div>}
            { readyToSendSales.length != 0 && <div className="horizontal-scrolling"><SaleTable sales={readyToSendSales} title="Payed & Ready to Send" handleRowClick={handleRowClick} handleStatusChange={handleStatusChange} optionsMP={optionsMP} optionsTransferencia={optionsTransferencia} colourStyles={colourStyles} selectedRow={selectedRow} /></div>}
            { packageSentSales != 0 && <div className="horizontal-scrolling"><SaleTable sales={packageSentSales} title="Package Sent" handleRowClick={handleRowClick} handleStatusChange={handleStatusChange} optionsMP={optionsMP} optionsTransferencia={optionsTransferencia} colourStyles={colourStyles} selectedRow={selectedRow} /></div>}
          </div>
         )
        }

        </div>
    </div>
  );
};

const numberFormat = (value) =>
  new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS'
  }).format(value);

const SaleTable = ({ sales, title, handleRowClick, handleStatusChange, optionsMP, optionsTransferencia, colourStyles, selectedRow }) => (
  <div>
    <h2>{title}</h2>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Client Name</th>
          <th>Total</th>
          <th>Product</th>
          <th>Payment Type</th>
          <th>Delivery Type</th>
          <th className="status-column">Status</th>
        </tr>
      </thead>
      <tbody>
        {sales.map(sale => (
          <React.Fragment key={sale.order_id}>
            <tr>
              <td  onClick={() => handleRowClick(sale.id)}>#{sale.order_id}</td>
              <td onClick={() => handleRowClick(sale.id)}>{sale.order_date.toDate().toLocaleDateString()}</td>
              <td onClick={() => handleRowClick(sale.id)}>{sale.payer.name} {sale.payer.surname}</td>
              <td onClick={() => handleRowClick(sale.id)}>{numberFormat(sale.item_price.toFixed(2))}</td>
              <td onClick={() => handleRowClick(sale.id)}>{sale.item_title}</td>
              <td onClick={() => handleRowClick(sale.id)}>{sale.payment_type}</td>
              <td onClick={() => handleRowClick(sale.id)}>
                {sale.shipping_type}
                {sale.shipping_type === "Enviamelo" ? ` - #${sale.shipping_id}` : ''}
            </td>
              <td className="status-column">
              <Select 
                className="sales-dropdown"
                name='item_condition-sales' 
                options={sale.payment_type === 'MP' ? optionsMP : optionsTransferencia} 
                styles={colourStyles}
                onChange={(selectedOption) => handleStatusChange(sale.id, selectedOption.value)}
                value={sale.payment_type === 'MP' ? optionsMP.find(option => option.value === sale.status) : optionsTransferencia.find(option => option.value === sale.status)}
                isDisabled={sale.status === 'Package Sent'}
                menuPortalTarget={document.body}
                onClick={(e) => e.stopPropagation()}
              />

              </td>
            </tr>
            {selectedRow === sale.id && (
            <tr style={{alignContent: "left", textAlign: "left"}}>
              <td colSpan="7">
                <div className="item-details-sales" style={{alignContent: "left", textAlign: "left"}}>
                  <img src={sale.item_image} alt={sale.item_name} className="item-image-sales"/>
                  <div>
                      <h2>{sale.item_title}</h2>
                      <p className="item_description-sales">{sale.item_description}</p>
                      <h3>${sale.item_price}</h3>
                  </div>
              </div>
              <div style={{fontSize:"13px", fontWeight:"400", alignContent: "left"}}>
                <h2>Order Summary</h2>
                <p><strong>Name:</strong> {sale.payer.name} {sale.payer.surname}</p>
                <p><strong>Email:</strong> {sale.payer.email}</p>
                <p><strong>Phone Number:</strong> +54 9 11 {sale.payer.phone.number}</p>
                <p><strong>Address:</strong> {sale.payer.address.street_name} {sale.payer.address.street_number}, {sale.payer.address.zip_code}</p>
                <p><strong>Notes: </strong> {sale.notes}</p>
                <p><strong>Delivery Option:</strong> {sale.shipping_type}</p>
                {sale.shipping_type === "Enviamelo" && (
                    <div className="order-summary">
                        <p><strong>Shipping ID:</strong> {sale.shipping_id}</p>
                        <p><strong>Shipping Tracking:</strong> {sale.shipping_tracking}</p>
                        <p>
                            <strong>Tracking PDF: </strong> 
                            <a href={sale.tracking_pdf} style={{color:"blue", cursor: "pointer"}} target="_blank" rel="noopener noreferrer">Open PDF</a>
                        </p>
                    </div>
                )}
              </div>
              
              </td>
            </tr>
          )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div>
);

export default Sales;
