import React from 'react';
import { useParams } from 'react-router-dom';

const PaymentPending = () => {
  const { itemId } = useParams();

  return (
    <div>
      <h1>Payment Pending</h1>
      <p>Your payment for item ID {itemId} is currently pending.</p>
    </div>
  );
};

export default PaymentPending;
