import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, updateProfile } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, setDoc, doc, getFirestore, getDocs, query, where, serverTimestamp } from "firebase/firestore";
import ArrowBackIcon from '../assets/arrow-left-solid.svg';
import '../Pages/Signup.css';

const Signup = () => {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [profilePic, setProfilePic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [isSignupComplete, setIsSignupComplete] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailFromQuery = queryParams.get("email");
  const [email, setEmail] = useState(emailFromQuery || '');  
  const restrictedUsernames = [
    'login', 'signup', '404', 'dashboard', 
    'select-payment', 'select-shipping', 'mercadopago', 
    'sales', 'payment-success', 'tracking', 'payment-failure', 
    'payment-pending', 'privacy', 'terms'
  ];
  

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setProfilePic(e.target.files[0]);
    }
  }  

  const handleSignup = async (event) => {
    event.preventDefault();
    setLoading(true);
    const auth = getAuth();
    const storage = getStorage();
    const db = getFirestore();

    const usernameRegex = /^[a-zA-Z0-9_]+$/;

    if (!usernameRegex.test(username)) {
      setError('Username can only contain letters, numbers, and underscores.');
      setLoading(false);
      return; // Stop the function here
    }

    if (restrictedUsernames.includes(username.toLowerCase())) {
      setError('This username is not available.');
      setLoading(false);
      return; // Stop the function here
    }

    try {
      // Check if the username is already taken
      const q = query(collection(db, "users"), where("username", "==", username.toLowerCase()));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        throw new Error('Username is already taken');
      }
  
      // Create the user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Upload the profile image to Firebase Storage
      const storageRef = ref(storage, `profile_pics/${username.toLocaleLowerCase()}/p_pic`);
      const uploadTask = uploadBytesResumable(storageRef, profilePic);
  
      uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
        }, 
        (error) => {
          console.log(error);
        }, 
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            
          // After user is created, update the profile with the username and profile image URL
          await updateProfile(auth.currentUser, {
            displayName: username.toLocaleLowerCase(),
            photoURL: downloadURL
          });
  
          // Add the user details to Firestore
          await setDoc(doc(db, "users", auth.currentUser.uid), {
            username: username.toLocaleLowerCase(),
            email: email,
            proimage: downloadURL,
            uid: userCredential.user.uid,
            date_created: serverTimestamp()
          });

          // Navigate once everything is done
          navigate('/select-payment');
          setIsSignupComplete(true);

        }
      );
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
};  

useEffect(() => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user && !isSignupComplete) {
      navigate('/dashboard');
    }
  });
}, [navigate, isSignupComplete]);


useEffect(() => {
  if (emailFromQuery) {
    setEmail(emailFromQuery);
  }
}, [emailFromQuery]);

const goBack = () => {
  navigate('/');
}


  return (
    <div className="signup">
      {!loading && (
                        <button className="back-button" onClick={goBack}>
                            <img src={ArrowBackIcon} alt="Back" />
                        </button>
                    )}
      <h1>Sign Up</h1>
      <form onSubmit={handleSignup}>
      <input 
          type="email" 
          name="email" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
      />

        <input type="password" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <input type="username" name="username" placeholder="@username" value={username.toLocaleLowerCase()} onChange={(e) => setUsername(e.target.value)} required />
        <input type="file" name="profilePic" accept="image/*" onChange={handleFileChange} required />
        <button type="submit" disabled={loading}>
          {loading ? "Loading..." : "Sign Up"}
        </button>
      </form>
      <p className="terms-conditions">
        By creating an account you are agreeing to our 
        <Link to="/terms" className="link"> Terms & Conditions </Link> 
        and to our 
        <Link to="/privacy" className="link"> Privacy Policy </Link>.
      </p>
      <Link to="/login" className="link account-has"> Already have an account? YEY! </Link>.
      {error && <div className="error">*{error}</div>}
    </div>
  );
};

export default Signup;
