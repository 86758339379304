import React, { useState, useEffect, Component } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import '../Pages/Login.css';
import ArrowBackIcon from '../assets/arrow-left-solid.svg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Adding useEffect to check if user is already logged in
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, redirect to dashboard
        navigate('/dashboard');
      }
    });
  }, [navigate]);

  const goBack = () => {
    navigate('/');
  }

  return (
    <div className="login">
       {!loading && (
                        <button className="back-button" onClick={goBack}>
                            <img src={ArrowBackIcon} alt="Back" />
                        </button>
                    )}
      <h1>Log In</h1>
      <form onSubmit={handleLogin}>
        <input type="email" name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <input type="password" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <button type="submit" disabled={loading}>
          {loading ? "Loading..." : "Log In"}
        </button>
      </form>
      <Link to="/signup" className="link account-has"> Don't have an account? Don't miss out! </Link>.
    </div>
  );
};

export default Login;
