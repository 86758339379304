import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronRight } from '../assets/chevron-right.svg';
import { ReactComponent as ChevronUp } from '../assets/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../assets/chevron-down.svg';
import { ReactComponent as PasteIcon } from '../assets/paste-icon.svg';
import { ReactComponent as FloppyDisk } from '../assets/floppy-disk.svg';
import { ReactComponent as AddNew } from '../assets/add-new.svg';
import { ReactComponent as RemoveIcon } from '../assets/minus-remove.svg';
import { ReactComponent as ChevronCheck } from '../assets/checkmark.svg';
import '../spinner.css';
import '../Pages/SelectPayment.css';
import { db, auth } from '../firebase';
import { collection, getDocs, query, where, updateDoc, doc, arrayUnion } from 'firebase/firestore';
import { upload } from '@testing-library/user-event/dist/upload';

const PaymentOptions = () => {
  const [user, setUser] = useState(null);

  const [isInputEmptyPickUp, setIsInputEmptyPickUp] = useState(true);
  const [isInputEmptyShipping, setIsInputEmptyShipping] = useState(true);
  const [isInputEmptyEnviamelo, setIsInputEmptyEnviamelo] = useState(true);

  const [pickUpOpen, setPickUpOpen] = useState(false);
  const [enviameloOpen, setEnviameloOpen] = useState(false);
  const [shipingProviderOpen, setShippingProviderOpen ] = useState(false);
  
  const [pickUpAdress, setPickUpAdress] = useState('');
  const [enviameloToken, setEnviameloToken] = useState('');
  const [shippingCost, setShippingCost] = useState('');
  const [shippingLabel, setShippingLabel] = useState('');

  const [uploadingPickUp, setUploadingPickUp] = useState(false); // New state for upload status
  const [isUploadedPickUp, setIsUploadedPickUp] = React.useState(false);
  
  const [uploadingShipping, setUploadingShipping] = useState(false); // New state for upload status
  const [isUploadedShipping, setIsUploadedShipping] = React.useState(false);

  const [uploadingEnviamelo, setUploadingEnviamelo] = useState(false); // New state for upload status
  const [isUploadedEnviamelo, setIsUploadedEnviamelo] = React.useState(false);

  const [shippingOptions, setShippingOptions] = useState([{ label: '', cost: '' }]);

  const handleAddShippingOption = () => {
    setShippingOptions([...shippingOptions, { label: '', cost: '' }]);
  };
  
  const handleRemoveShippingOption = (index) => {
    const newShippingOptions = [...shippingOptions];
    newShippingOptions.splice(index, 1);
    setShippingOptions(newShippingOptions);
  };

  const handleShippingOptionChange = (index, field, value) => {
    const newShippingOptions = shippingOptions.map((option, i) => {
      if (i === index) {
        return { ...option, [field]: field === 'cost' ? parseInt(value) : value };
      }
      return option;
    });
    setShippingOptions(newShippingOptions);
  };
  
  const navigate = useNavigate();

  useEffect(() => {
    setIsInputEmptyPickUp(pickUpAdress === '');
    setIsInputEmptyShipping(shippingCost === '');
    setIsInputEmptyEnviamelo(enviameloToken === '');
  }, [pickUpAdress, shippingCost, enviameloToken]);

  const handleContinue = () => {
    if (!isInputEmptyPickUp) {
      saveToFirebasePickup();
    }
    if (!isInputEmptyShipping) {
      handleSaveShippingOptions();
    }
    if (!isInputEmptyEnviamelo) {
      saveToFirebaseEnviamelo();
    }
    navigate('/dashboard');
  };

  const pasteFromClipboard = async () => {
    if (!navigator.clipboard) {
      return;
    }
    const text = await navigator.clipboard.readText();
    setPickUpAdress(text);
  };

  const pasteFromClipboardShipping = async () => {
    if (!navigator.clipboard) {
      return;
    }
    const text = await navigator.clipboard.readText();
    setShippingCost(text);
  };

  const pasteFromClipboardEnviamelo = async () => {
    if (!navigator.clipboard) {
      return;
    }
    const text = await navigator.clipboard.readText();
    setEnviameloOpen(text);
  };

  const handlePickUpOpen = () => {
    setPickUpOpen(!pickUpOpen);
  };

  const handleEnviameloOpen = () => {
    setEnviameloOpen(!enviameloOpen);
  };

  const handleShippingProviderOpen = () => {
    setShippingProviderOpen(!shipingProviderOpen);
  };

  const saveToFirebasePickup = async () => {
    if (auth.currentUser.uid && !uploadingPickUp) {
      setUploadingPickUp(true);

      const q = query(collection(db, "users"), where("uid", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      let docId = null;
      querySnapshot.forEach((doc) => {
        docId = doc.id;
      });

      if (docId) {
        const userDoc = doc(db, 'users', docId);
        await updateDoc(userDoc, {
          pickup_address: pickUpAdress,
          shipping_settings: arrayUnion("Pick-Up")
        });
      
        // Add this line to update the user object in the state
      
        setUploadingPickUp(false);
        setIsUploadedPickUp(true);
      }
      
    }
  }

  const saveToFirebaseEnviamelo = async () => {
    if (auth.currentUser.uid && !uploadingEnviamelo) {
      setUploadingEnviamelo(true);

      const q = query(collection(db, "users"), where("uid", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      let docId = null;
      querySnapshot.forEach((doc) => {
        docId = doc.id;
      });

      if (docId) {
        const userDoc = doc(db, 'users', docId);
        const privateDoc = doc(db, 'users', docId, 'private', 'data');
        await updateDoc(userDoc, {
          shipping_settings: arrayUnion("Enviamelo")
        });
        await updateDoc( privateDoc, {
          enviamelo_token: enviameloToken,
        });
      
        // Add this line to update the user object in the state
      
        setUploadingEnviamelo(false);
        setIsUploadedEnviamelo(true);
      }
      
    }
  }

  const handleSaveShippingOptions = async () => {
    if (auth.currentUser.uid && !uploadingShipping) {
      setUploadingShipping(true);
  
      const q = query(collection(db, "users"), where("uid", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      let docId = null;
      querySnapshot.forEach((doc) => {
        docId = doc.id;
      });
  
      if (docId) {
        const userDoc = doc(db, 'users', docId);
        const shippingData = shippingOptions.map(option => ({
          label: option.label,
          price: parseInt(option.cost)
        }));
        await updateDoc(userDoc, {
          custom_shipping: arrayUnion(...shippingData),
          shipping_settings: arrayUnion("Shipping")
        });
        setUploadingShipping(false);
        setIsUploadedShipping(true);
      }
    }
  };

  return (
    <div className="dashboard-wrapper">
    <h1>Configurar Entregas</h1>
    <div className="payment-option">
        <div onClick={handlePickUpOpen} className="payment-option-title">
            Retiro en Domicilio
            <div className="icon-button">
            {pickUpOpen ? <ChevronDown /> : <ChevronUp />}
            </div>
        </div>
        {pickUpOpen && 
            <div style={{ display: 'flex', alignItems: 'center', padding: '1em' }}>
            {uploadingPickUp || isUploadedPickUp
            ? <span>{pickUpAdress}</span>
            : <input 
                type="text" 
                value={pickUpAdress} 
                onChange={e => setPickUpAdress(e.target.value)} 
                className="payment-input"
                placeholder="Dirección de Retiro"
                />}
            <div onClick={isInputEmptyPickUp ? pasteFromClipboard : saveToFirebasePickup}>
                {uploadingPickUp 
                    ? <div className="spinner"></div> 
                    : isUploadedPickUp
                    ? <ChevronCheck /> 
                    : isInputEmptyPickUp 
                        ? <PasteIcon />
                        : <FloppyDisk />}
            </div>
            </div>
        }
    </div>
    <div className="payment-option">
        <div onClick={handleEnviameloOpen} className="payment-option-title">
            Enviamelo
            <div className="icon-button">
            {enviameloOpen ? <ChevronDown /> : <ChevronUp />}
            </div>
        </div>
        {enviameloOpen && 
            <div style={{ display: 'flex', alignItems: 'center', padding: '1em' }}>
            {uploadingEnviamelo || isUploadedEnviamelo
            ? <span>{enviameloToken}</span>
            : <input 
                type="text" 
                value={enviameloToken} 
                onChange={e => setEnviameloToken(e.target.value)} 
                className="payment-input"
                placeholder="Token de Enviamelo"
                />}
            <div onClick={isInputEmptyEnviamelo ? pasteFromClipboardEnviamelo : saveToFirebaseEnviamelo}>
                {uploadingEnviamelo 
                    ? <div className="spinner"></div> 
                    : isUploadedEnviamelo
                    ? <ChevronCheck /> 
                    : isInputEmptyEnviamelo 
                        ? <PasteIcon />
                        : <FloppyDisk />}
            </div>
            </div>
        }
    </div>
    {/* <div className="payment-option">
      <div onClick={handleShippingProviderOpen} className="payment-option-title">
        Envío a Domicilio
        <div className="icon-button">
          {shipingProviderOpen ? <ChevronDown /> : <ChevronUp />}
        </div>
      </div>
      {shipingProviderOpen && (
        <div>
          {shippingOptions.map((option, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '1em 0' }}>
              <input
                type="text"
                value={option.label}
                onChange={e => handleShippingOptionChange(index, 'label', e.target.value)}
                className="payment-input"
                placeholder="Etiqueta de Envío"
                style={{ width: '50%' }}
              />
              <input
                type="number"
                min="0"
                value={option.cost}
                onChange={e => handleShippingOptionChange(index, 'cost', e.target.value)}
                className="payment-input"
                placeholder="Precio de Envío"
                style={{ width: '50%' }}
              />
              <button onClick={() => handleRemoveShippingOption(index)} style={{ marginRight: '1em', border: '0', background: '#F3F3F3' }}>
                <RemoveIcon />
              </button>
              <button onClick={handleAddShippingOption} style={{ marginRight: '1em', border: '0', background: '#F3F3F3' }}>
                <AddNew />
              </button>
            </div>
          ))}
          <div style={{ display: 'flex', alignItems: 'center', padding: '1em' }}>
            <div onClick={handleSaveShippingOptions}>
              {uploadingShipping ? (
                <div className="spinner"></div>
              ) : 
                <FloppyDisk />
              }
            </div>
          </div>
        </div>
      )}
    </div> */}
    <button onClick={handleContinue} className="payment-button black">
        Continuar
        <div className="icon-button-contiune">
        <ChevronRight />
        </div>
    </button>
    </div>

  );
};

export default PaymentOptions;
