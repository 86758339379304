import React from 'react';
import { useParams } from 'react-router-dom';
import User from '../Pages/User';
import Store from '../Pages/Store';

const UserPage = () => {
  let { username } = useParams();

  return (
    <div>
      <User username={username} />
      <Store username={username} />
      <a href="https://wearlyapp.com" style={{bottom: '2%', left: 0, right: 0, margin: 'auto', textAlign: 'center'}}>
        <p style={{color:'grey', fontStyle: 'italic', fontSize:'10px'}}>Powered by Wearly</p>
      </a>
    </div>
  );
};

export default UserPage;
