
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import '../Pages/PaymentTracking.css';
import { db, auth } from '../firebase';
import checkmark from '../assets/checkmark.svg';
import { collection, getDocs, getDoc, query, where, updateDoc, doc, arrayUnion, addDoc, deleteDoc, arrayRemove } from 'firebase/firestore';

const PaymentTracking = () => {
    const location = useLocation();

    // This will give you an object of all query parameters
    const queryParams = new URLSearchParams(location.search);

    // Example: To access a specific query parameter, e.g., "id"
    const id = queryParams.get('id');
    const orderUID = queryParams.get('order');
    const [enviameloPDF, setEnviameloPDF] = useState('');
    const [orderStatus, setOrderStatus] = useState('');
    const [orderID, setOrderID] = useState('');
    const [enviamelo, setEnviamelo] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const salesDoc = await getDoc(doc(db, 'users', id, 'sales', orderUID));
            if (!salesDoc.empty) {
                const data = salesDoc.data();
                if (data.shipping_type === 'Enviamelo') {
                    setEnviameloPDF(data.tracking_pdf);
                    setEnviamelo(true);
                }
                setOrderStatus(data.status);
                setOrderID(data.order_id);
            }
        };
        
        // Call the inner async function
        fetchData();
    }, []);

  return (
      <div className="payment-success-container">
          { orderStatus == "Package Sent" || orderStatus == "Payed & Ready to Send" ? <img src={checkmark} alt="Success" className="checkmark"/> : null}
          <h2 className="success-title">Estado de tu compra: {orderStatus}</h2>
          <p className="success-message">Thank you for your purchase. Your order ID is: #{orderID}.</p>
          <p style={{fontWeight:"400"}}className="success-message">The seller will be sending your item or contacting you soon. Please keep an eye for the order email with the details.</p>
          {enviamelo && <p style={{fontWeight:"400"}}className="success-message">Puede seguir tu pedido <a href={enviameloPDF}>aqui</a></p>}
      </div>
  );
}

export default PaymentTracking;


