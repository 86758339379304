
import React from 'react';
import { useLocation } from 'react-router-dom';  // <-- Import useLocation
import '../Pages/PaymentSuccess.css';
import checkmark from '../assets/checkmark.svg'; // Add your checkmark.svg to your project and import it here
import ConfettiExplosion from 'react-confetti-explosion';


const PaymentSuccess = () => {
  const location = useLocation();
  
  // This will give you an object of all query parameters
  const queryParams = new URLSearchParams(location.search);

  // Example: To access a specific query parameter, e.g., "id"
  const id = queryParams.get('id');

  return (
    <div className="payment-success-container">
      <div className="payment-success-container">
          <img src={checkmark} alt="Success" className="checkmark"/>
          <ConfettiExplosion />
          <h2 className="success-title">Payment Successful!</h2>
          <p className="success-message">Gracias por su compra! Esta fue recibida y sera procesada en breve. 🧐</p>
          <p style={{fontWeight:"400"}}className="success-message">El vendedor le enviará el artículo o se pondrá en contacto con en breve. <br></br>Esté atento al correo electrónico del pedido con los detalles y el link de seguimiento. 🔥</p>
      </div>
    </div>
  );
}

export default PaymentSuccess;


