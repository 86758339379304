import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAxfMju4BcKGPj-zfTc3ufPdIkuuJ9-s3o",
    authDomain: "wearly-test-ac39b.firebaseapp.com",
    projectId: "wearly-test-ac39b",
    storageBucket: "wearly-test-ac39b.appspot.com",
    messagingSenderId: "1075803999228",
    appId: "1:1075803999228:web:583d0fd118b67065fac079",
    measurementId: "G-36ZQGEKQNC"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export { db, auth, onAuthStateChanged, functions, httpsCallable, createUserWithEmailAndPassword, updateProfile, storage, ref, uploadBytesResumable, getDownloadURL, analytics };
