import React from 'react';

const PaymentFailure = () => (
  <div>
    <h1>Payment Failure</h1>
    <p>Sorry, your payment did not go through. Please try again.</p>
  </div>
);

export default PaymentFailure;
