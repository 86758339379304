import React, { useState, useEffect, useRef } from 'react';
import {db} from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import '../Pages/ItemPage.css';
import ArrowBackIcon from '../assets/arrow-left-solid.svg';

const ItemPage = () => {
  const [item, setItem] = useState({});
  const { username, itemName, id } = useParams();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true); // Initialize the loading state
  
  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const firstChild = container.firstChild;
      if(firstChild) {
        const style = window.getComputedStyle(firstChild);
        const width = parseFloat(style.width);
        const marginRight = parseFloat(style.marginRight);
        const scrollTo = currentIndex * (width + marginRight);
        container.scrollLeft = scrollTo;
      }
    }
  }, [currentIndex]);

  useEffect(() => {
    const getItem = async () => {
      setIsLoading(true); // Set loading to true when starting to fetch
      const itemDoc = await getDocs(collection(db, 'stores'));
      const itemData = itemDoc.docs.find(doc => doc.data().id === id);
      if (itemData) {
        setItem(itemData.data());
      } else {
        navigate('/404');
      }
      setIsLoading(false); // Set loading to false once data is fetched
    };
    getItem();
  }, [username, itemName]);

  const goBack = () => {
    navigate(-1);
  }

  if (isLoading) {
    return <div>Loading...</div>; // Or any other placeholder or spinner
  }

  const numberFormat = (value) =>
  new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS'
  }).format(value);

  return (
    <div className="item-page-client">
       <button className="back-button" onClick={goBack}>
        <img src={ArrowBackIcon} alt="Back" />
      </button>

      {Array.isArray(item?.item_image) && item?.item_image.length === 1 ? (
        <div className="single-image-container">
          <img src={item.item_image[0]} alt="Preview" />
        </div>
      ) : (
        <div className="item-image-container-client" ref={containerRef}>
          {Array.isArray(item?.item_image) && item.item_image.map((url, index) => (
            <div style={{ marginRight: index === item.item_image.length - 1 ? '0px' : '25px' }} key={index}>
              <style>
            {`
              div::-webkit-scrollbar {
                display: none;
              }
            `}
          </style>
              <img src={url} alt="Preview" />
            </div>
          ))}
        </div>
      )}
    <div className="dots">
      {Array.isArray(item?.item_image) && item?.item_image.length > 1 && item.item_image.map((_, index) => (
        <div 
          key={index} 
          className={`dot ${index === currentIndex ? 'active' : ''}`}
          onClick={() => setCurrentIndex(index)}
        />
      ))}
    </div>
        {/* <div className={`item-condition ${item.item_condition}`}>{item.item_condition}</div> */}
      
      <div className="item-text-container">
        <h2>{item.item_name}</h2>
        <p>{item.item_description}</p>
        <h3>{numberFormat(item.item_price)}</h3>
      </div>
      {item.archived ? (
        <button className="buy-button" style={{backgroundColor:'#af4c4c', cursor: 'not-allowed', fontFamily: 'Raleway', fontWeight:'700'}}>SOLD</button>
      ) : (
        <button className="buy-button" onClick={() => navigate(`/${username}/${itemName}/checkout`)}>Buy Now</button>
      )

      }
    </div>
  );
};

export default ItemPage;
