import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Pages/Home.css';
import Logo from '../assets/logo.png';

function HomePage() {
  const [email, setEmail] = useState(""); 
  const navigate = useNavigate();  // <-- Change this line

  return (
    <div className="mac-book-pro-14-1">
        <div className="navbar"> 
            <div className="logoo">
                <img src={Logo} alt="Logo" />
            </div>
            <div className="contact-button3" style={{cursor: "pointer"}}>
                <button 
                    style={{cursor: "pointer"}}
                    className="inicio-sesion"
                    onClick={() => navigate('/login')}  // <-- Adjust this line
                >
                    Iniciar Sesion
                </button>
            </div>
        </div>
        
        <h1 className="title">Una nueva forma<br />de vender ropa online</h1>
        <h5 className="subtitle">
            Un link para vincular con tus redes sociales <br />para exponer tu ropa como
            si fuese tu instagram
        </h5>
        
        <div className="contact-button">
            <div className="contact-button2" 
                    style={{cursor: "pointer"}}>
            <button 
                    style={{cursor: "pointer"}}
                className="crear-link" 
                onClick={() => navigate(`/signup?email=${email}`)}  // <-- Adjust this line
            >
                Crear Link
            </button>

            </div>
            <input 
                className="tu-email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                placeholder="Tu email" 
            />
        </div>
    </div>
  );
}

export default HomePage;
