import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { useNavigate, Link, Router } from 'react-router-dom';
import { ReactComponent as CreditCard } from '../assets/credit-card.svg';
import '../Pages/Dashboard.css';
import { ReactComponent as ChevronRight } from '../assets/chevron-right.svg';
import { ReactComponent as LinkCopy } from '../assets/link-solid.svg';
import { ReactComponent as ChevronUp } from '../assets/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../assets/chevron-down.svg';
import { ReactComponent as FloppyDisk } from '../assets/floppy-disk.svg';
import { ReactComponent as ShippingTruck } from '../assets/truck-fast.svg';
import { ReactComponent as AddNew } from '../assets/add-new.svg';
import { ReactComponent as RemoveIcon } from '../assets/minus-remove.svg';
import { ReactComponent as ChevronCheck } from '../assets/checkmark.svg';
import TrashSolid from '../assets/trash-solid.js';
import '../spinner.css';
import '../Pages/SelectPayment.css';
import { v4 as uuidv4 } from 'uuid';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject, uploadBytes } from "firebase/storage";
import { collection, getDocs, getDoc, query, where, updateDoc, doc, arrayUnion, addDoc, deleteDoc, setDoc, arrayRemove } from 'firebase/firestore';


const Dashboard = () => {
  const [items, setItems] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  
  const [paymentSettings, setPaymentSettings] = useState([]);
  const [shippingSettings, setShippingSettings] = useState([]);

  const [transfer, setTransfer] = useState('');
  const [isEditingCBU, setIsEditingCBU] = useState(false);
  const [isEditingPickUp, setIsEditingPickUp] = useState(false);
  const [isEditingEnviamelo, setIsEditingEnviamelo] = useState(false);
  const [isEditingShipping, setIsEditingShipping] = useState(false);
  const [shippingLabel, setShippingLabel] = useState('');
  const [shippingPrice, setShippingPrice] = useState('');
  const [showShippingOptions, setShowShippingOptions] = useState(false);
  const [cbuAliasOpen, setCbuAliasOpen] = useState(false);
  const [pickUpOpen, setPickUpOpen] = useState(false);
  const [enviameloOpen, setEnviameloOpen] = useState(false);
  const [shipingProviderOpen, setShippingProviderOpen ] = useState(false);
  const [pickUpAdress, setPickUpAdress] = useState('');
  const [enviameloToken, setEnviameloToken] = useState('');
  const [shippingCost, setShippingCost] = useState('');
  const [uploading, setUploading] = useState(false); // New state for upload status
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadingPickUp, setUploadingPickUp] = useState(false); // New state for upload status
  const [isUploadedPickup, setIsUploadedPickup] = React.useState(false);
  const [uploadingEnviamelo, setUploadingEnviamelo] = useState(false); // New state for upload status
  const [isUploadedEnviamelo, setIsUploadedEnviamelo] = React.useState(false);
  const [uploadingShipping, setUploadingShipping] = useState(false); // New state for upload status
  const [isUploadedShipping, setIsUploadedShipping] = React.useState(false);

  const [itemState, setItemState] = useState(''); // New state for upload status
  const navigate = useNavigate();

  const [shippingOptions, setShippingOptions] = useState([{ label: '', cost: '' }]);
  const [shippingOptionsFB, setShippingOptionsFB] = useState([{ label: '', cost: '' }]);

  const handleAddShippingOption = () => {
    const newShippingOption = {
      label: shippingLabel,
      price: parseFloat(shippingPrice)
    };
    const newShippingOptions = [...shippingOptions, newShippingOption];
    setShippingOptions(newShippingOptions);
    setShowShippingOptions(false);
    setShippingLabel('');
    setShippingPrice('');
  };
  
  const handleRemoveShippingOption = async (index) => {
    const newShippingOptions = [...shippingOptions];
    const removedOption = newShippingOptions.splice(index, 1)[0];
    setShippingOptions(newShippingOptions);
  
    if (auth.currentUser.uid) {
      const q = query(collection(db, "users"), where("uid", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      let docId = null;
      querySnapshot.forEach((doc) => {
        docId = doc.id;
      });
  
      if (docId) {
        const userDoc = doc(db, 'users', docId);
        const userDocSnap = await getDoc(userDoc);
        const customShippingData = userDocSnap.data().custom_shipping || [];
        const shippingOptionExists = customShippingData.some(option => option.label === removedOption.label && option.price === removedOption.cost);
        if (shippingOptionExists) {
          await updateDoc(userDoc, {
            custom_shipping: arrayRemove({ label: removedOption.label, price: removedOption.cost })
          });
          const newShippingOptionsFB = shippingOptionsFB.filter(option => option.label !== removedOption.label || option.price !== removedOption.cost);
          setShippingOptionsFB(newShippingOptionsFB);
        }
      }
    }
  };

  const handleShippingOptionChange = (index, key, value) => {
    const newShippingOptions = [...shippingOptions];
    const option = newShippingOptions[index];
    if (key === 'cost' && (value === '' || isNaN(parseFloat(value)))) {
      return;
    }
    option[key] = value;
    setShippingOptions(newShippingOptions);
  };

  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);

  useEffect(() => {
    const getUserItems = async () => {
      setLoading(true);
      const userSnapshot = await getDoc(doc(db, 'users', auth.currentUser.uid));
      const privateDoc = await getDoc(doc(db, 'users', auth.currentUser.uid, 'private', 'data'));

      if (!userSnapshot.empty) {
        setUser(userSnapshot.data());
        const userData = userSnapshot.data();
        const privData = privateDoc.data();

        if (userData?.shipping_settings?.includes('Pick-Up')) {
          setPickUpAdress(userData.pickup_address);
        };

        if (userData?.shipping_settings?.includes('Enviamelo')) {
          setEnviameloToken(privData.enviamelo_token);
        };

        if (userData?.shipping_settings?.includes('Shipping')) {
          const shippingOptionsData = userData.custom_shipping || [];
          setShippingOptions(shippingOptionsData);
          setShippingOptionsFB(shippingOptionsData);
        };

        if (userData?.payment_settings?.includes('CBU')) {
          setTransfer(userData.transfer);
        };

        // If you also want to keep checking for 'mp_token'
        // if (!userData.mp_token) missingTokens.push({ token: 'mp_token', link: 'https://mercadopago.com' });
        // if (!userData.andreani_token) missingTokens.push({ token: 'andreani_token', link: 'https://developers.andreani.com/document' });

        // Set payment settings and transfer data
        
        setPaymentSettings(userData.payment_settings || []);
        setShippingSettings(userData.shipping_settings || []);

        const userItems = query(collection(db, 'stores'), where("username", "==", userData.username));
        const itemSnapshot = await getDocs(userItems);
        
        if (!itemSnapshot.empty) {
          const itemsData = itemSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setItems(itemsData);
        }
    
      }
      setLoading(false);
    }
    getUserItems();
  
  }, []);

  const handleMercadoPago = () => {
    mercadopago();
  };

  const handleCBUAliasOpen = () => {
    setCbuAliasOpen(!cbuAliasOpen);
  };

  const handlePickUpOpen = () => {
    setPickUpOpen(!pickUpOpen);
  };

  const handleEnviameloOpen = () => {
    setEnviameloOpen(!enviameloOpen);
  };

  const handleShippingProviderOpen = () => {
    setShippingProviderOpen(!shipingProviderOpen);
  };

  const handleShippingLabelChange = (event) => {
    setShippingLabel(event.target.value);
  };

  const handleShippingPriceChange = (event) => {
    setShippingPrice(event.target.value);
  };

  const [imageFile, setImageFile] = useState(null); // to hold the new image file
  const [previewImage, setPreviewImage] = useState(null); // for previewing the selected image

  const inputFileRef = React.useRef(null); // ref for the input

  const [isSaving, setIsSaving] = useState(false);

  const handleImageClick = () => {
    // Trigger the hidden input file on user image click
    inputFileRef.current.click();
  };

  const onImageChange = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      setImageFile(file);
      const previewUrl = URL.createObjectURL(file);
      setPreviewImage(previewUrl);
      setLoading(true);
      await saveChanges(file);  // Pass the file directly
    }
};

const saveChanges = async (fileToUpload) => { // Take file as an argument
    setIsSaving(true);

    const storage = getStorage();
    console.log(fileToUpload);  // This should log the file now

    const storageRef = ref(storage, `profile_pics/${user.username.toLocaleLowerCase()}/p_pic`);
    const uploadTask = uploadBytesResumable(storageRef, fileToUpload);
  
      uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
        }, 
        (error) => {
          console.log(error);
        }, 
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
  
          const updatedItem = {
            proimage: downloadURL,
          };
          await updateDoc(doc(db, 'users', user.uid), updatedItem);
          console.log('Image updated successfully!'); 
          setLoading(false);

        }
      );
  }

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopyLink = () => {
    const textToCopy = `https://wearlyapp.com/${user.username}`;
    navigator.clipboard.writeText(textToCopy);
  };

  const deleteItem = async (id, event) => {
    event.stopPropagation();
    event.preventDefault();
    const confirmation = window.confirm('Are you sure you want to delete this item?');
    if (confirmation) {
      // Get the item's image URL
      const itemToDelete = items.find(item => item.id === id);
  
      // If item has an image
      if (itemToDelete?.item_image) {
        const storage = getStorage();
        let imageRef = ref(storage, itemToDelete.item_image);
  
        // Delete the image
        try {
          await deleteObject(imageRef);
          console.log("Image deleted successfully!");
        } catch (error) {
          console.log("Uh-oh, an error occurred!", error);
        }
      }
  
      // Delete the document
      await deleteDoc(doc(db, 'stores', id));
      setItems(items.filter((item) => item.id !== id));
    }
  };

  // Prevent user from adding new item if tokens are missing
  const handleAddItem = () => {
    if (shippingSettings && shippingSettings.length === 0 && paymentSettings && paymentSettings.length === 0) {
      alert('Please add shipping options & payment options to your account before adding new items.');
      return;
    }
    if (paymentSettings && paymentSettings.length === 0) {
      alert('Please add payment options to your account before adding new items.');
      return;
    }
    if (shippingSettings && shippingSettings.length === 0) {
      alert('Please add shipping options to your account before adding new items.');
      return;
    }
    setShowModal(true);
  };

  const handleNavigate = () => {
    navigate('/sales');  // Navigates to the sales page
  };

  const handleFileChange = (e) => {
    const selectedFiles = [...files, ...e.target.files]; // Concatenate new files with existing ones
    setFiles(selectedFiles);
    
    const urls = selectedFiles.map(file => URL.createObjectURL(file));
    setPreviewUrls(urls);
};


  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    
    const newPreviewUrls = [...previewUrls];
    newPreviewUrls.splice(index, 1);
  
    setFiles(newFiles);
    setPreviewUrls(newPreviewUrls);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUploading(true);
  
    // Firebase Storage service
    const storage = getStorage();
    const folderUUID = uuidv4(); // Unique UUID for the folder
  
    try {
      // Array to hold the download URLs
      const downloadURLs = await Promise.all(files.map(async (file, index) => {
        const storageRef = ref(storage, `items/${folderUUID}/${uuidv4()}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
  
        return new Promise((resolve, reject) => {
          uploadTask.on('state_changed', 
            (snapshot) => {
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
            },
            (error) => {
              console.log(error);
              reject(error);
            }, 
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            }
          );
        });
      }));
  
      // Form data
      const item_name = event.target.elements.item_name.value;
      const item_price = Number(event.target.elements.item_price.value);
      const item_condition = event.target.elements.item_condition.value;
      const item_style = event.target.elements.item_style.value;
      const item_brand = event.target.elements.item_brand.value;
      const item_description = event.target.elements.item_description.value;
  
      // Construct the newItem object
      const newItem = {
        item_image: downloadURLs,
        item_name,
        item_price,
        username: user.username,
        item_condition,
        item_style,
        item_brand,
        item_description,
        archived: false,
        userID: user.uid,
        q: 1,
        currency: "ARS"
      };
  
      // Save the newItem to Firestore
      const docRef = await addDoc(collection(db, 'stores'), newItem);
  
      // Update the document with the id
      const newItemRef = doc(db, "stores", docRef.id);
      await updateDoc(newItemRef, {
        id: docRef.id
      });
  
      setShowModal(false);
    } catch (error) {
      console.error("Error during the upload or Firestore save operation: ", error);
    } finally {
      setIsUploading(false);
    }
  };
  
  
  const handleCloseModal = () => {
    setShowModal(false);
  };  

  if (loading) {
    return <div>Loading...</div>;  // Render a loading message while fetching data
  }

  const handleCreditCardClick = () => {
    handleOpenPaymentOptions();
  };
  
  const handleShippingClick = () => {
    handleOpenShippingOptions();
  }

  const handleOpenPaymentOptions = () => {
    setShowPaymentOptions(true);
  };

  const handleOpenShippingOptions = () => {
    setShowShippingOptions(true);
  };

  const handleCloseShippingOptions = () => {
    setShowShippingOptions(false);
  };
  const handleClosePaymentOptions = () => {
    setShowPaymentOptions(false);
  };
  

  const mercadopago = () => {
    const clientId = process.env.REACT_APP_MP_APP_ID;
    const redirectUri = process.env.REACT_APP_MP_REDIRECT_URI;
    const state = Math.random().toString(36).substring(7); // create a random string for state parameter
    
    const mpAuthUrl = `https://auth.mercadopago.com/authorization?client_id=${clientId}&response_type=code&platform_id=mp&state=${state}&redirect_uri=${redirectUri}`;
    console.log(mpAuthUrl);
    // Redirect the user to MercadoPago authorization URL
    window.location.href = mpAuthUrl;
  };

  const saveToFirebase = async () => {
    if (auth.currentUser.uid && !uploading) {
      setUploading(true);

      const q = query(collection(db, "users"), where("uid", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      let docId = null;
      querySnapshot.forEach((doc) => {
        docId = doc.id;
      });

      if (docId) {
        const userDoc = doc(db, 'users', docId);
        await updateDoc(userDoc, {
          transfer: transfer,
          payment_settings: arrayUnion("CBU")
        });

        setUser(prevUser => ({
          ...prevUser,
          transfer: transfer,
          payment_settings: Array.isArray(prevUser?.payment_settings) ? [...prevUser.payment_settings, "CBU"] : ["CBU"]
        }));
        

        setUploading(false);
        setIsUploaded(true);
        setTimeout(() => {setIsUploaded(false)}, 2000);
      }
    }
  };

  const saveToFirebasePickup = async () => {
    if (auth.currentUser.uid && !uploadingPickUp) {
      setUploadingPickUp(true);

      const q = query(collection(db, "users"), where("uid", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      let docId = null;
      querySnapshot.forEach((doc) => {
        docId = doc.id;
      });

      if (docId) {
        const userDoc = doc(db, 'users', docId);
        await updateDoc(userDoc, {
          pickup_address: pickUpAdress,
          shipping_settings: arrayUnion("Pick-Up")
        });
      
        setUser(prevUser => ({
          ...prevUser,
          pickup_address: pickUpAdress,
          shipping_settings: Array.isArray(prevUser?.shipping_settings) ? [...prevUser.shipping_settings, "Pick-Up"] : ["Pick-Up"]
        }));
        
        setUploadingPickUp(false);
        setIsUploadedPickup(true);
        setTimeout(() => {setIsUploadedPickup(false)}, 2000);
      }
      
    }
  }

  const saveToFirebaseEnviamelo = async () => {
    if (auth.currentUser.uid && !uploadingEnviamelo) {
      console.log(enviameloToken);
      setUploadingEnviamelo(true);

      const q = query(collection(db, "users"), where("uid", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      let docId = null;
      querySnapshot.forEach((doc) => {
        docId = doc.id;
      });

      if (docId) {
        const userDoc = doc(db, 'users', docId);
        const privateDoc = doc(db, 'users', docId, 'private', 'data');
        await updateDoc(userDoc, {
          shipping_settings: arrayUnion("Enviamelo")
        });
        await setDoc( privateDoc, {
          enviamelo_token: enviameloToken,
        }, { merge: true });
      
        setUser(prevUser => ({
          ...prevUser,
          enviamelo_token: enviameloToken,
          shipping_settings: Array.isArray(prevUser?.shipping_settings) ? [...prevUser.shipping_settings, "Enviamelo"] : ["Enviamelo"]
        }));
        
        setUploadingEnviamelo(false);
        setIsUploadedEnviamelo(true);
        setTimeout(() => {setIsUploadedEnviamelo(false)}, 2000);
      }
      
    }
  }

  const handleSaveShippingOptions = async () => {
    if (auth.currentUser.uid && !uploadingShipping) {
      setUploadingShipping(true);

      const q = query(collection(db, "users"), where("uid", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      let docId = null;
      querySnapshot.forEach((doc) => {
        docId = doc.id;
      });

      if (docId) {
        const userDoc = doc(db, 'users', docId);
        const shippingData = shippingOptions.map(option => ({
          label: option.label,
          price: parseInt(option.cost)
        }));
        console.log(shippingData)
        if (shippingData.length > 0) {
          await updateDoc(userDoc, {
            custom_shipping: shippingData,
            shipping_settings: arrayUnion("Shipping")
          });
          const newShippingOptionsFB = [...shippingOptionsFB, ...shippingData];
          setShippingOptionsFB(newShippingOptionsFB);
          setUser(prevUser => ({
            ...prevUser,
            custom_shipping: Array.isArray(prevUser?.custom_shipping) ? [...prevUser.custom_shipping, {label: shippingLabel, price: parseInt(shippingPrice)}] : [{label: shippingLabel, price: parseInt(shippingPrice)}],
            shipping_settings: Array.isArray(prevUser?.shipping_settings) ? [...prevUser.shipping_settings, "Shipping"] : ["Shipping"]
          }));
          setUploadingShipping(false);
          setIsUploadedShipping(true);
          setTimeout(() => {setIsUploadedShipping(false)}, 2000);
        }
        
      }

    }
  }

  // const deletePaymentOption = async (option) => {
  //   if (auth.currentUser.uid) {
  //     const q = query(collection(db, "users"), where("uid", "==", auth.currentUser.uid));
  //     const querySnapshot = await getDocs(q);
  //     let docId = null;
  //     querySnapshot.forEach((doc) => {
  //       docId = doc.id;
  //     });

  //     if (docId) {
  //       const userDoc = doc(db, 'users', docId);
  //       await updateDoc(userDoc, {
  //         payment_settings: arrayRemove(option)
  //       });
      
  //       // Add this line to update the user object in the state
  //       setUser(prevUser => ({
  //         ...prevUser,
  //         payment_settings: prevUser?.payment_settings?.filter((item) => item !== option)
  //       }));
  //     }
  //   }
  // }

  // const deleteShippingOption = async (option) => {
  //   if (auth.currentUser.uid) {
  //     const q = query(collection(db, "users"), where("uid", "==", auth.currentUser.uid));
  //     const querySnapshot = await getDocs(q);
  //     let docId = null;
  //     querySnapshot.forEach((doc) => {
  //       docId = doc.id;
  //     });

  //     if (docId) {
  //       const userDoc = doc(db, 'users', docId);
  //       await updateDoc(userDoc, {
  //         shipping_settings: arrayRemove(option)
  //       });
      
  //       // Add this line to update the user object in the state
  //       setUser(prevUser => ({
  //         ...prevUser,
  //         shipping_settings: prevUser?.shipping_settings?.filter((item) => item !== option)
  //       }));
  //     }
  //   }
  // }

  const numberFormat = (value) =>
    new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS'
    }).format(value);

  const handleItemClick = (item) => {
    navigate(`${item.username}/${item.id}`);
  }

  return (
    <div className='dashboard-wrapper'>
   {paymentSettings && paymentSettings.length === 0 && (
      <div className="alert-container">
        <p>
          To start uploading your wardrobe, please add payment option.
        </p>
      </div>
    )}
    {shippingSettings && shippingSettings.length === 0 && (
      <div className="alert-container">
        <p>
          To start uploading your wardrobe, please configure shipping options.
        </p>
      </div>
    )}
    {/* {missingTokens.length > 0 && (
      <div className="alert-container">
        <p>
          To start uploading your wardrobe, please 
          {missingTokens.map((tokenObj, index) => (
            <React.Fragment key={tokenObj.token}>
              &nbsp;link your&nbsp;
              <a href={tokenObj.link} target="_blank" rel="noopener noreferrer">{tokenObj.token}</a>
              {index < missingTokens.length - 1 && ', '}
            </React.Fragment>
          ))}
        </p>
      </div>
    )} */}
        <div className='dashboard-container'>
          <div className="user-container">
            <div className="dashboard-header">
              <h1>Dashboard</h1>
              <div className="signout-container">
              <button className="icon-btn" onClick={handleShippingClick}>
                <ShippingTruck />
              </button>
              <button className="icon-btn" onClick={handleCreditCardClick}>
                <CreditCard />
              </button>
                <button onClick={handleSignOut} className="signout-btn">Cerrar Sesión</button>
              </div>
            </div>
            <input 
              type="file"
              style={{ display: 'none' }} 
              ref={inputFileRef}
              onChange={onImageChange}
            />
            <img src={ previewImage || user.proimage} alt={user.username} onClick={handleImageClick} className="user-avatar-dashboard" />
          <div className='user-namelink'>
            <h2 className="user-username">@{user.username}</h2>
            <button onClick={handleCopyLink} className="copy-link-btn">
              <LinkCopy />
            </button>
          </div>
          <button onClick={handleNavigate} className="sales-btn">Ventas</button>
        </div>
        <div className="dashboard-store-container">
        {items.map((item, index) => (
          <div 
            key={index} 
            onClick={() => handleItemClick(item)} 
            style={{ cursor: 'pointer' }}
          >
            <div className="dashboard-store-item">
              <img src={item.item_image[0]} alt={item.item_name} className="dashboard-store-item-img" />
              <button className="dashboard-store-item-trash" onClick={(event) => deleteItem(item.id, event)}>
                <TrashSolid style={{ width: '15px', height: '15px' }} />
              </button>
              <div className="condition-container">
                  {item.archived && <div className="item-condition-status sold">sold 💸</div>}
              </div>
            </div>
            <div className="item-text-container-store">
              <h2 style={{fontSize:'14px'}}>{item.item_name}</h2>
              <h3 style={{fontSize:'12px'}}>{numberFormat(item.item_price)}</h3>
            </div>
          </div>
        ))}
        </div>
        <button onClick={handleAddItem} className="add-button">Add Item</button>
        {showPaymentOptions && (
          <div className='modal'>
            <div className='modal-content-payment'>
              <button onClick={handleClosePaymentOptions} className='modal-close-btn'>X</button>
              <h1>Configurar Pagos</h1>
              <button onClick={handleMercadoPago} className="payment-button">
                Configurar Mercado Pago  *
                <div className="icon-button">
                  {user && user?.payment_settings && user?.payment_settings.includes('MP') ? <ChevronCheck /> : <ChevronRight />}
                </div>
              </button>
              <div className="payment-option">
                  <div onClick={handleCBUAliasOpen} className="payment-option-title">
                      Agregar CBU/Alias
                      <div className="icon-button">
                      {cbuAliasOpen ? <ChevronDown /> : <ChevronUp />}
                      </div>
                  </div>
                  {cbuAliasOpen && 
                    <div style={{ display: 'flex', alignItems: 'center', padding: '1em' }}>
                    <input 
                        type="text" 
                        onFocus={() => setIsEditingCBU(true)}
                        onBlur={() => setIsEditingCBU(false)}
                        onChange={e => setTransfer(e.target.value)}
                        value={isEditingCBU ? transfer : (user?.payment_settings?.includes('CBU') ? user?.transfer : '')}
                        className="payment-input"
                        placeholder="CBU / Alias"
                    />
                    <div onClick={saveToFirebase}>
                      {uploading 
                        ? <div className="spinner"></div> 
                        : isUploaded
                        ? <ChevronCheck />
                        : <FloppyDisk />
                      }
                    </div>
                    </div>
                  }
                  </div>
            <p style={{textAlign: "left !important", alignItems: "flex-start !important", fontSize: "10px"}}>*  1% (fee Wearly) + hasta 6.99% + IVA (fee <a href='https://www.mercadopago.com.ar/ayuda/costo-recibir-pagos_220'>MercadoPago</a>)</p>
            </div>
          </div>
        )}
        {showShippingOptions && (
          <div className='modal'>
            <div className='modal-content-payment'>
              <button onClick={handleCloseShippingOptions} className='modal-close-btn'>X</button>
              <h1>Configurar Entregas</h1>
              <div className="payment-option">
                  <div onClick={handlePickUpOpen} className="payment-option-title">
                      Retiro en Domicilio
                      <div className="icon-button">
                      {pickUpOpen ? <ChevronDown /> : <ChevronUp />}
                      </div>
                  </div>
                  {pickUpOpen && 
                    <div style={{ display: 'flex', alignItems: 'center', padding: '1em' }}>
                      <input 
                          type="text" 
                          onFocus={() => setIsEditingPickUp(true)}
                          onBlur={() => setIsEditingPickUp(false)}
                          onChange={e => setPickUpAdress(e.target.value)}
                          value={isEditingPickUp ? pickUpAdress : (user?.shipping_settings?.includes('Pick-Up') ? user?.pickup_address : '')}
                          className="payment-input"
                          placeholder="Dirección de Retiro"
                      />
                      <div onClick={saveToFirebasePickup}>
                        {uploadingPickUp 
                          ? <div className="spinner"></div> 
                          : isUploadedPickup
                          ? <ChevronCheck />
                          : <FloppyDisk />
                        }
                      </div>
                    </div>
                  }
                </div>
                <div className="payment-option">
                  <div onClick={handleEnviameloOpen} className="payment-option-title">
                      Enviamelo
                      <div className="icon-button">
                      {enviameloOpen ? <ChevronDown /> : <ChevronUp />}
                      </div>
                  </div>
                  {enviameloOpen && 
                    <div style={{ display: 'flex', alignItems: 'center', padding: '1em' }}>
                      <input 
                          type="text" 
                          onFocus={() => setIsEditingEnviamelo(true)}
                          onBlur={() => setIsEditingEnviamelo(false)}
                          onChange={e => setEnviameloToken(e.target.value)}
                          value={isEditingEnviamelo ? enviameloToken : (user?.shipping_settings?.includes('Enviamelo') ? enviameloToken : '')}
                          className="payment-input"
                          placeholder="Token de Enviamelo"
                      />
                      <div onClick={saveToFirebaseEnviamelo}>
                        {uploadingEnviamelo 
                          ? <div className="spinner"></div> 
                          : isUploadedEnviamelo
                          ? <ChevronCheck />
                          : <FloppyDisk />
                        }
                      </div>
                    </div>
                  }
                </div>
              {/* <div className="payment-option">
                  <div onClick={handleShippingProviderOpen} className="payment-option-title">
                      Envío a Domicilio
                      <div className="icon-button">
                      {shipingProviderOpen ? <ChevronDown /> : <ChevronUp />}
                      </div>
                  </div>
                  {shipingProviderOpen && 
                    <div>
                    {shippingOptions.map((option, index) => (
                      <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '1em 0' }}>
                        <input
                          type="text"
                          value={option.label}
                          onChange={e => handleShippingOptionChange(index, 'label', e.target.value)}
                          className="payment-input"
                          placeholder="Etiqueta de Envío"
                          style={{ width: '50%' }}
                        />
                        <input
                          type="number"
                          min="0"
                          value={option.price}
                          onChange={e => handleShippingOptionChange(index, 'cost', e.target.value)}
                          className="payment-input"
                          placeholder="Precio de Envío"
                          style={{ width: '50%' }}
                        />
                        <button onClick={() => handleRemoveShippingOption(index)} style={{ marginRight: '1em', border: '0', background: '#F3F3F3' }}>
                          <RemoveIcon />
                        </button>
                        <button onClick={handleAddShippingOption} style={{ marginRight: '1em', border: '0', background: '#F3F3F3' }}>
                          <AddNew />
                        </button>
                      </div>
                    ))}
                    <div style={{ display: 'flex', alignItems: 'center', padding: '1em' }}>
                      {shippingOptions.length === 0 &&
                        <button type="button" onClick={handleAddShippingOption} style={{ marginRight: '1em', border: '0', background: '#F3F3F3' }}>
                          <AddNew />
                        </button>
                      }
                      <div onClick={handleSaveShippingOptions}>
                        {uploadingShipping ? (
                          <div className="spinner"></div>
                        ) : 
                          <FloppyDisk />
                        }
                      </div>
                    </div>
                  </div>
                  }
                </div> */}
            </div>
          </div>
        )}
        {showModal && (
          <div className='modal'>
            <div className='modal-content'>
              <button onClick={handleCloseModal} className='modal-close-btn'>X</button>
              <form onSubmit={handleSubmit} className='modal-form'>
                <div style={{ 
                  overflowX: 'auto', 
                  whiteSpace: 'nowrap',
                  scrollSnapType: 'x mandatory',
                  marginBottom: '25px',
                }}>
                  <style>
                    {`
                      div::-webkit-scrollbar {
                        display: none;
                      }
                    `}
                  </style>
                  {previewUrls.map((url, index) => (
                    <div style={{ position: 'relative', display: 'inline-block', marginRight: '25px' }} key={index}>
                      <img src={url} 
                          alt="Preview" 
                          style={{ 
                            width: '100px', 
                            height: '100px', 
                            objectFit: 'cover',
                          }} 
                      />
                      <button style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: '#f3f3f3',
                        border: 'none',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0',
                      }} 
                        onClick={(e) => { e.preventDefault(); handleRemoveFile(index); }}
                      >
                        <TrashSolid style={{ width: '15px', height: '15px', fill: '#000' }} />
                      </button>
                    </div>
                  ))}
                </div>
                <label for="fileInput" className="add-button-image">
                <span>Select Images</span>
                <input 
                  type="file" 
                  id="fileInput" 
                  multiple
                  name="item_image" 
                  required 
                  onChange={handleFileChange}
                  style={{display: 'none'}}
                />
              </label>
                <input name='item_name' placeholder='Item Name' required />
                <input name='item_price' type='number' placeholder='Item Price' required />
                <select name='item_condition' required>
                  <option value=''>Select Condition</option>
                  <option value='new'>New</option>
                  <option value='used'>Used</option>
                </select>
                <select name='item_style' required>
                  <option value=''>Select Style</option>
                  <option value='streetwear'>Streetwear</option>
                  <option value='sportswear'>Sportswear</option>
                  <option value='loungewear'>Loungewear</option>
                  <option value='y2_k'>Y2K</option>
                  <option value='goth'>Goth</option>
                  <option value='retro'>Retro</option>
                  <option value='trap'>Trap</option>
                  <option value='casual'>Casual</option>
                  <option value='techwear'>Utility</option>
                  <option value='futuristic'>Futuristic</option>
                  <option value='boho'>Boho</option>
                  <option value='western'>Western</option>
                  <option value='indie'>Indie</option>
                  <option value='skater'>Skater</option>
                  <option value='cute'>Cute</option>
                  <option value='chic'>Chic</option>
                  <option value='rave'>Rave</option>
                  <option value='pastel'>Pastel</option>
                  <option value='bright'>Bright</option>
                  <option value='costume'>Costume</option>
                  <option value='cosplay'>Cosplay</option>
                  <option value='grunge'>Grunge</option>
                  <option value='party'>Party</option>
                  <option value='funky'>Funky</option>
                  <option value='emo'>Emo</option>
                  <option value='minimalist'>Minimalist</option>
                  <option value='preppy'>Preppy</option>
                  <option value='avant_garde'>Avant Garde</option>
                  <option value='punk'>Punk</option>
                  <option value='glam'>Glam</option>
                  <option value='regency'>Regency</option>
                  <option value='cottage'>Cottage</option>
                  <option value='fairy'>Fairy</option>
                  <option value='kidcore'>Kidcore</option>
                  <option value='biker'>Biker</option>
                  <option value='gorpcore'>Gorpcore</option>
                  <option value='twee'>Twee</option>
                  <option value='coquette'>Coquette</option>
                  <option value='whimsygoth'>Whimsygoth</option>
                </select>
                <select name='item_brand' required>
                  <option value=''>Select Brand</option>
                  <option value='nike'>Nike</option>
                  <option value='adidas'>adidas</option>
                  <option value='puma'>Puma</option>
                  <option value='h_m'>H&M</option>
                  <option value='zara'>Zara</option>
                  <option value='forever_21'>Forever 21</option>
                  <option value='north_face'>North Face</option>
                  <option value='new_balance'>New Balance</option>
                  <option value='air_jordan'>Air Jordan</option>
                  <option value='supreme'>Supreme</option>
                  <option value='fear_of_god'>Fear of God</option>
                  <option value='saint_laurent'>Saint Laurent</option>
                  <option value='cdg'>Comme des Garçons</option>
                  <option value='calvin_klein'>Calvin Klein</option>
                  <option value='champion'>Champion</option>
                  <option value='dr_martens'>Dr. Martens</option>
                  <option value='anti_social_social_club'>Anti Social Social Club</option>
                  <option value='other'>Otra</option>
                </select>
                <textarea name='item_description' style={{resize: 'none', width: '100%', height: 'auto'}} placeholder='Item Description' required />
                <button type='submit' disabled={isUploading}>
                    {isUploading ? "Uploading..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default Dashboard;