import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams, useNavigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import UserPage from './Pages/UserPage';
import ItemPage from './Pages/ItemPage';
import Checkout from './Pages/Checkout';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import MercadoPago from './Pages/MercadoPago';
import EditItemPage from './Pages/EditItemPage';
import SalesPage from './Pages/Sales';
import './spinner.css';
import PaymentSuccess from './Pages/PaymentSuccess';
import PaymentFailure from './Pages/PaymentFailure';
import PaymentPending from './Pages/PaymentPending';
import SelectPayment from './Pages/SelectPayment';
import SelectShipping from './Pages/SelectShipping';
import ErrorPage from './Pages/404';
import Home from './Pages/Home';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import PaymentTracking from './Pages/PaymentTracking'


function PrivateWrapper({children}) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      setUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div className="spinner"></div>; // Display the spinner
  }
  
  return user ? children : <Navigate to="/login" />;
}

function OwnerOnlyWrapper({children}) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { username } = useParams(); // Grab the username from the URL
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      console.log("Firebase User:", user); // Add this line
      setUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, [auth]);

  console.log("Username from URL:", username); // Add this line


  if (loading) {
    return <div className="spinner"></div>; // Display the spinner
  }

  if (user && user.displayName !== username) {
    console.log("Username mismatch. Redirecting..."); // Add this line
    return <Navigate to="/login" />;
  }
  
  return children;
}


function PublicWrapper({children}) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      setUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div className="spinner"></div>; // Display the spinner
  }
  
  return children;
}

function HandlePaymentSuccess() {
  const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const collectionStatus = queryParams.get("collection_status");
    const orderUidFromQuery = queryParams.get("merchant_order_id") || queryParams.get("order_uid");
    const orderIdFromQuery = queryParams.get("payment_id") || queryParams.get("order_id");
    const itemIdFromQuery = queryParams.get("preference_id") || queryParams.get("item_id");

    if (collectionStatus === "approved") {
      return <Navigate to={`/payment-success/${orderUidFromQuery}/${orderIdFromQuery}/${itemIdFromQuery}`} />;
    } else {
      return <Navigate to={`/payment-success/${orderUidFromQuery}/${orderIdFromQuery}/${itemIdFromQuery}`} />;
    }
}

function App() {
  return (
    <Router>
      <Routes>

        <Route path="/" element={<PublicWrapper><Home /></PublicWrapper>} />
        <Route path="/login" element={<PublicWrapper><Login /></PublicWrapper>} />
        <Route path="/signup" element={<PublicWrapper><Signup /></PublicWrapper>} />
        <Route path="/:username" element={<PublicWrapper><UserPage /></PublicWrapper>} />
        <Route path="/:username/:itemName/:id" element={<PublicWrapper><ItemPage /></PublicWrapper>} />
        <Route path="/:username/:itemName/checkout" element={<PublicWrapper><Checkout /></PublicWrapper>} />
        <Route path="/404" element={<PublicWrapper><ErrorPage/></PublicWrapper>} />
        
        <Route path="/dashboard" element={<PrivateWrapper><Dashboard /></PrivateWrapper>} />

        <Route path="/select-payment" element={<PrivateWrapper><SelectPayment /></PrivateWrapper>} />
        <Route path="/select-shipping" element={<PrivateWrapper><SelectShipping /></PrivateWrapper>} />
        <Route path="/mercadopago" element={<PrivateWrapper><MercadoPago /></PrivateWrapper>} />
        <Route path="/sales" element={<PrivateWrapper><SalesPage /></PrivateWrapper>} />

        <Route path="/dashboard/:username/:id" element={<OwnerOnlyWrapper><EditItemPage /></OwnerOnlyWrapper>} />

        <Route path="/payment-success" element={<PublicWrapper><PaymentSuccess/></PublicWrapper>} />

        <Route path='/tracking' element={<PublicWrapper><PaymentTracking/></PublicWrapper>} />

        <Route path="/payment-success/:transactionId" element={<PublicWrapper><PaymentSuccess/></PublicWrapper>} />

        <Route path="/payment-failure" element={<PaymentFailure />} />
        <Route path="/payment-pending/:itemId" element={<PaymentPending />} />

        <Route path="/privacy" element={<PublicWrapper><Privacy /></PublicWrapper>} />
        <Route path="/terms" element={<PublicWrapper><Terms /></PublicWrapper>} />
      </Routes>
    </Router>
  );
}

export default App;