import React, { useState, useEffect } from 'react';
import {db} from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import '../Pages/Checkout.css';
import ArrowBackIcon from '../assets/arrow-left-solid.svg';
import { getFunctions, httpsCallable } from "firebase/functions";
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import { ReactComponent as CopyIcon } from '../assets/paste-icon.svg';
import '../spinner.css';
import { ReactComponent as ChevronUp } from '../assets/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../assets/chevron-down.svg';
import { getAnalytics, logEvent } from "firebase/analytics";

const provinces = ["Buenos Aires", "CABA", "Catamarca", "Chaco", "Chubut", "Córdoba", "Corrientes", "Entre Ríos", "Formosa", "Jujuy", "La Pampa", "La Rioja", "Mendoza", "Misiones", "Neuquén", "Río Negro", "Salta", "San Juan", "San Luis", "Santa Cruz", "Santa Fe", "Santiago del Estero", "Tierra del Fuego", "Tucumán"];

const Checkout = () => {
    const [formData, setFormData] = useState({
        email: "",
        id: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        street: "",
        streetNumber: "",
        apartment: "",
        floor: "",
        neighborhood: "",
        city: "",
        zipCode: "",
        notes: "",
        province: provinces[0] // defaulting to the first province
    });

    const [preferenceId, setPreferenceId] = useState(null);
    const [isPaymentActive, setIsPaymentActive] = useState(false);
    const [hasError, setHasError] = useState(false);
    
    const [loading, setLoading] = useState(false);

    const analytics = getAnalytics();
    const [item, setItem] = useState({});
    const [userSettings, setUserSettings] = useState({});
    const { username, itemName } = useParams();
    const [paymentSettings, setPaymentSettings] = useState([]);
    const [paymentType, setPaymentType] = useState(null);
    const [transferDetails, setTransferDetails] = useState("");
    const [mpPublicKey, setmpPublicKey] = useState("");
    const [zipCodeShipping, setZipCodeShipping] = useState("");
    const [mpOpen, setMpOpen] = useState(false);
    const [totalCost, setTotalCost] = useState(0);    
    const [cbuAliasOpen, setCbuAliasOpen] = useState(false);
    const [deliveryOption, setDeliveryOption] = useState("");
    const [selectingError, setSelectingError] = useState(false);
    const [isOrderCreated, setIsOrderCreated] = useState(false);
    const [isEnviameloCP, setIsEnviameloCP] = useState(false);
    const [isCBUOrderCreating, setIsCBUOrderCreating] = useState(false);
    const [enviameloPrice, setEnviameloPrice] = useState(null);

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        const getItem = async () => {
            const itemDoc = await getDocs(collection(db, 'stores'));
            const itemData = itemDoc.docs.find(doc => doc.data().item_name === itemName && doc.data().username === username);
            if (itemData && !itemData.data().archived) {
                setItem(itemData.data());
            } else {
                navigate('/404');
            }
        };
        getItem();
    }, [username, itemName]);
    
    useEffect(() => {
        const fetchPaymentSettings = async () => {
            const userDoc = await getDocs(collection(db, 'users'));
            const userData = userDoc.docs.find(doc => doc.data().username === username);
            setUserSettings(userData.data());
            setPaymentSettings(userData.data().payment_settings);
            setTransferDetails(userData.data().transfer || ""); // Assuming transferDetails are stored in 'transfer' field of user data
            const key = userData.data().public_key || "";
            setmpPublicKey(key);
            if (key) {
                initMercadoPago(key);
            }
        };
        fetchPaymentSettings();
    }, [username, itemName]);

    useEffect(() => {
        if (deliveryOption === "Shipping") {
            setTotalCost(item?.item_price + (deliveryOption === "Shipping" ? userSettings?.shipping_price_settings : 0));
        } else if (deliveryOption === "Enviamelo") {
            setTotalCost(item?.item_price + enviameloPrice);
        }else {
            setTotalCost(item?.item_price);
        }
    }, [item, userSettings, deliveryOption]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleZipCodeShipping = (e) => {
        setZipCodeShipping(e.target.value);
        setFormData({ ...formData, "zipCode": e.target.value });
    };

    const handleEmail = (e) => {
        setFormData({ ...formData, "email": e.target.value });
    };

    const handleDNI = (e) => {
        setFormData({ ...formData, "id": e.target.value });
    };
    
    const handleDeliveryOptionChange = (e) => {
        setDeliveryOption(e.target.value);
    };

    const functions = getFunctions();
    const createPreference = httpsCallable(functions, 'createPreference');
    const createCBUOrder = httpsCallable(functions, 'createCBUOrder');
    const shippingPriceEnviamelo = httpsCallable(functions, 'shippingPriceEnviamelo');

    const handleSubmit = (e) => {
        if (deliveryOption != "") {
            setIsOrderCreated(true);
            setIsPaymentActive(true);
        } else {
            setSelectingError(true);
        }
    };

    const handlePaymentType = (type) => {
        if (type === 'CBU') {
            setMpOpen(false);
            setCbuAliasOpen(!cbuAliasOpen);
            setMpOpen(false);
        } else if (type === 'MP') {
            setLoading(true);
            handleMP();
        }
    };    

    const copyToClipboard = () => {
        navigator.clipboard.writeText(transferDetails);
    };

    const handleMP = () => {
        logEvent(analytics, 'purchase');
        setIsPaymentActive(true);
        setLoading(true);
        createPreference({...formData, itemId: item.id, username, deliveryOption})
        .then((result) => {
            const preference = result.data;
            console.log(preference);
            if (preference.id) {
                console.log('Preference ID:', preference.id);
                setPreferenceId(preference.id);
                setIsPaymentActive(true);
                setLoading(false);
                setCbuAliasOpen(false);
                setMpOpen(!mpOpen);
                setCbuAliasOpen(false);
                console.log('Preference Type:', typeof preference.id);
                console.log('Preference created successfully');
            } else {
                console.log('Failed to create preference');
            }
        })
        .catch((error) => {
            setHasError(true);
            console.log('Error:', error);
            setLoading(false);
            setIsPaymentActive(false);
            setIsOrderCreated(false);
            setCbuAliasOpen(false);
            setMpOpen(false);
            setCbuAliasOpen(false);
        });
    }

    const handleCBU = (e) => {
        logEvent(analytics, 'purchase');
        e.preventDefault();
        setLoading(true);
        setIsCBUOrderCreating(true); // CBU order creation starts here
        setIsPaymentActive(false);
        console.log(item.id)
        createCBUOrder({...formData, itemId: item.id, username, deliveryOption})
            .then((result) => {
                const preference = result.data;
                console.log(result);
                if (preference.status === true) {
                    setIsPaymentActive(true);
                    setIsOrderCreated(true); // Add this line
                    setIsCBUOrderCreating(false); // CBU order creation ends here
                    // Navigate to PaymetnSuccess.js here and pass on preference.order_id and preference.order_uid
                    navigate(`/payment-success`);
                } else {
                    setHasError(true);
                    console.log('Error:', "FB error");
                    setLoading(false);
                    setIsPaymentActive(false);
                    setIsCBUOrderCreating(false); // CBU order creation ends here in case of error
                    setIsOrderCreated(false);
                    
                }
            })
            .catch((error) => {
                setHasError(true);
                console.log('Error:', error);
                setLoading(false);
                setIsPaymentActive(false);
                setIsCBUOrderCreating(false); // CBU order creation ends here in case of error
                setIsOrderCreated(false);
            });
    }

    const handleEnviameloPrice = (e) => {
        if (zipCodeShipping.length !== 4 || zipCodeShipping === "" || zipCodeShipping === null || zipCodeShipping === undefined || zipCodeShipping === "0000") {
            console.log("what")
        } else if (!userSettings.shipping_settings.includes("Enviamelo")) {
            console.log("No Enviamelo");
            setIsEnviameloCP(true);
            setLoading(false);
        } else {
            setLoading(true);
            e.preventDefault();
            console.log(zipCodeShipping);
            shippingPriceEnviamelo({uid: userSettings.uid, postal_code: zipCodeShipping})
            .then((result) => {
                const funcResult = result.data;
                setEnviameloPrice(funcResult.amount);
                setIsEnviameloCP(true);
                setLoading(false);

            })
            .catch((error) => {
                setHasError(true);
                console.log('Error:', error);
                setLoading(false);
                setIsPaymentActive(false);
                setIsCBUOrderCreating(false); // CBU order creation ends here in case of error
                setIsOrderCreated(false);
                setLoading(false);
            });
        }
    }

    const numberFormat = (value) =>
    new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS'
    }).format(value);

    return (     
        <div className="checkout">
            <div className="checkout-header">
                {!isCBUOrderCreating && (
                        <button className="back-button" onClick={goBack}>
                            <img src={ArrowBackIcon} alt="Back" />
                        </button>
                    )}
                <h1 className='checkout-title'>Checkout</h1>
            </div>
            <div className="item-details">
                <img src={item.item_image} alt={item.item_name} className="item-image"/>
                <div>
                    <h2>{item.item_name}</h2>
                    <p className="item_description">{item.item_description}</p>
                    <h3>{numberFormat(item.item_price)}</h3>
                </div>
            </div>
            {!isEnviameloCP ? (
                <form onSubmit={handleEnviameloPrice}>
                    <input type="number" min="0" name="zipCodeShipping" placeholder="CP" onChange={handleZipCodeShipping} required />
                    <input type="email" name="email" placeholder="Email" onChange={handleEmail} required disabled={isPaymentActive} />
                    <input type="number" min="0" name="id" placeholder="DNI" onChange={handleDNI} required disabled={isPaymentActive} />
                    <button className="order-button" type="submit">Continuar</button>
                 </form>
            ) : (
                !isOrderCreated ? (
                    // Before this calculate the total cost and show it here on the checkbox or ask radio before this.
                    <div>
                        <h3>Your Details</h3>
                        <form onSubmit={handleSubmit}>
                            <input type="text" name="firstName" placeholder="Nombre" onChange={handleChange} required disabled={isPaymentActive} />
                            <input type="text" name="lastName" placeholder="Apellido" onChange={handleChange} required disabled={isPaymentActive} />
                            <input type="tel" name="phoneNumber" placeholder="Numero de Telefono (solo los 8 ultimos digitos)" onChange={handleChange} required disabled={isPaymentActive} />
                            <input type="text" name="street" placeholder="Calle" onChange={handleChange} required disabled={isPaymentActive} />
                            <div className="row">
                                <input className="streetNumber" min="0" type="number" name="streetNumber" placeholder="Nº de Calle" onChange={handleChange} required disabled={isPaymentActive} />
                                <input type="text" name="apartment" placeholder="Departamento" onChange={handleChange} disabled={isPaymentActive} />
                                <input type="text" name="floor" placeholder="Piso" onChange={handleChange} disabled={isPaymentActive} />
                            </div>
                            <input type="text" name="neighborhood" placeholder="Barrio" onChange={handleChange} required disabled={isPaymentActive} />
                            <input type="text" name="city" placeholder="Ciudad" onChange={handleChange} required disabled={isPaymentActive} />
                            <select name="province" onChange={handleChange} required disabled={isPaymentActive}>
                                {provinces.map((province, index) => (
                                    <option key={index} value={province}>
                                        {province}
                                    </option>
                                ))}
                            </select>
                            <input type="text" name="notes" placeholder="Notas de Envio" onChange={handleChange} disabled={isPaymentActive} />
                            <div className="checkout-delivery-options">
                                <h3>Delivery Options</h3>
                                {userSettings.shipping_settings.includes("Pick-Up") &&
                                    <label>
                                        <input type="radio" className="rounded-checkbox" name="deliveryOption" value="Pick-Up" checked={deliveryOption === "Pick-Up"} onChange={handleDeliveryOptionChange} />
                                        <span>Pick-Up - FREE @ {userSettings.pickup_address}</span>
                                    </label>
                                }
                                {userSettings.shipping_settings.includes("Enviamelo") &&
                                     <label>
                                        <input type="radio" className="rounded-checkbox" name="deliveryOption" value="Enviamelo" checked={deliveryOption === "Enviamelo"} onChange={handleDeliveryOptionChange} />
                                        <span>Envío: + {numberFormat(enviameloPrice)}</span>
                                    </label>
                                }
                                {userSettings.shipping_settings.includes("Shipping") &&
                                    userSettings.custom_shipping.map((option, index) => (
                                        <label key={index}>
                                          <input
                                            type="radio"
                                            className="rounded-checkbox"
                                            name="deliveryOption"
                                            value={option.label}
                                            checked={deliveryOption === option.label}
                                            onChange={handleDeliveryOptionChange}
                                          />
                                          <span>Envío - {option.label} + {numberFormat(option.price)}</span>
                                        </label>
                                      ))
                                }
                                <h5>* Por favor elegir una opcion</h5>
                            </div>
                            {hasError && 
                                <div style={{ color: 'red', fontWeight: 'bold', marginTop: '10px' }}>
                                    An error occurred while processing your payment. Please try again.
                                </div>
                            }
                            {selectingError &&
                                <div style={{ color: 'red', fontWeight: 'bold', marginTop: '10px' }}>
                                    Seleccione una opcion de envio.
                                </div>
                            }
                            <h2 className="checkout-total-cost">Total: {numberFormat(totalCost)}</h2>
                            {!isPaymentActive && <button class="order-button" type="submit">Crear Pedido</button>}
                        </form>
                    </div>
                ) : (
                    <div style={{fontSize:"13px", fontWeight:"400"}}>
                        <h2>Resumen:</h2>
                        <p>{formData.firstName} {formData.lastName}</p>
                        <p>{formData.email}</p>
                        <p>+54 9 11 {formData.phoneNumber}</p>
                        <p>{formData.street} {formData.streetNumber}, {formData.apartment}, {formData.neighborhood}, {formData.city}, {formData.province}, {formData.zipCode}</p>
                        <p>Opcion de Envío: {deliveryOption}</p>
                    </div>
                )
            )}
            {paymentType === null && isPaymentActive &&
                <div>
                    <h3>Payment Type</h3>
                    {paymentSettings.includes('CBU') && 
                        <div style={{ background: '#F3F3F3', borderRadius: '9px', marginBottom: '1em' }}>
                            <button 
                                onClick={(e) => handlePaymentType('CBU', e)} 
                                className="payment-option-title"
                                style={{ border: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '1em' }}
                            >
                                Pagar con CBU/Alias
                                <div className="icon-button">
                                    {cbuAliasOpen ? <ChevronDown /> : <ChevronUp />}
                                </div>
                            </button>
                            {cbuAliasOpen &&
                                <div>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '1em' }}>
                                    <span className="payment-input">{transferDetails}</span>
                                    <div onClick={(e) => copyToClipboard(e)} style={{cursor: "pointer"}}>
                                        <CopyIcon />
                                    </div>
                                </div>
                                <h5>* Por favor apretar "comprar" una vez que realices la transferencia</h5>
                                <button class="purchase-transfer" onClick={handleCBU}>Comprar</button>
                                </div>
                            }
                        </div>
                    
                    }
                    {paymentSettings.includes('MP') && 
                        <div style={{ background: '#F3F3F3', borderRadius: '9px', marginBottom: '1em' }}>
                            <button 
                                onClick={() => handlePaymentType('MP')} 
                                className="payment-option-title"
                                style={{ border: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '1em' }}
                            >
                                Pagar con Mercado Pago
                                <div className="icon-button">
                                    {mpOpen ? <ChevronDown /> : <ChevronUp />}
                                </div>
                            </button>
                            {mpOpen && preferenceId &&
                                <Wallet
                                    initialization={{ preferenceId: preferenceId, redirectMode: 'self' }}
                                />
                            }
                        </div>
                    }
                <h2 className="checkout-total-cost">Total: {numberFormat(totalCost)}</h2>
                </div>
            }
            {isCBUOrderCreating && <div className="spinner"></div>}
            {loading && 
                <div className="loading-overlay">
                    <div className="spinner"></div>
                </div>
            }
        </div>
    );
};

export default Checkout;
