import React, { useState, useEffect } from 'react';
import '../Pages/User.css';
import {db} from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

const User = ({username}) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    console.log('Effect is running...');
    const q = query(collection(db, 'users'), where("username", "==", username));
    getDocs(q)
      .then((snapshot) => {
        if(!snapshot.empty){
          setUser(snapshot.docs[0].data());
          console.log(snapshot.docs[0].data());
        }
      });
  }, [username]);

  return (
    <div className="user-container-client">
      {console.log(user.proimage)}
      <img src={user.proimage} alt={user.username} className="user-avatar-store" />
      <h2 className="user-username">@{user.username}</h2>
    </div>
  );
};

export default User;